


    .cart-content {
        max-height: calc(100vh - 200px);
        overflow-x: hidden!important;

        .extended & {
            max-height: none;
        }
    }

    .cart-empty {
        color: var(--global-primary-background);
        text-align: center;
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;

        > * {
            color: var(--global-primary-background);
            text-align: center;
        }
    }

    .cart-title {
        position: relative;
        padding-top: calc(var(--global-small-gutter)*0.2);
        padding-right: calc(var(--global-small-gutter)*0.8);
        padding-bottom:calc(var(--global-small-gutter)*0.6);
        padding-left: calc(var(--global-small-gutter)*0.8);
    }

    .cart-title-text {
        position: relative;
        top: 3px;
        font-size:var(--text-large-font-size);
    }

    .uk-offcanvas .cart-proceed-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        .uk-button {
            &:hover {
                background-color: var(--global-background)!important;
                color: var(--global-primary-background)!important;
            }
        }

    }

    .cart-header-final-price {
        color: var(--global-primary-background)!important;
        font-size: calc(var(--global-font-size)*1.1);
        font-weight: 500;
    }

    .cart-header-summery {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .cart-header-final-price{
        margin-right: 2px;
    }

    .cart-main {
        max-height: calc(100% - 130px )
    }

    .entry-price {
        width: 95px;
        flex: 0 0 95px;

        .entry-final-price {
            white-space: nowrap;
            border-radius: var(--border-radius-small);
        }

        .cart-price-separator{
            margin: 5px 0;
            height: 1px;
            width: 100%;
            background: var(--global-muted-color);
        }

        .entry-final-price {
            text-align: center;
            width: 100%;
            padding: calc(var(--global-small-gutter)/4) calc(var(--global-small-gutter)/2);
            background: var(--global-primary-background);
            color: var(--global-background);
            font-weight: 600;
        }

        .entry-old-price {
            text-align: center;
            width: 100%;

        }
    }

    .entry-image {
        border: 2px solid var(--global-muted-background);
        padding: 2px;
        width: 100px;
        flex: 0 0 100px;
    }

    .entry-title{
        font-weight: 600;
    }

    .entry-detail {
        font-size: calc(var(--global-font-size)*0.8);
    }

    .details-wrap {
        .extended & {
            min-width: 170px;
        }
    }
    .details-cta {
        max-width: 400px;
    }


    .type-price-summary {
        height: auto;
        align-self: flex-start;
        padding-top: calc(var(--global-margin) * 1);
        padding-left: calc(var(--global-margin) * 0.75);
        padding-right: calc(var(--global-margin) * 0.75);
        padding-bottom: calc(var(--global-margin) * 1);
   //     border: 1px solid red;

    }

    .price-type-block {
        padding-top: calc(var(--global-margin) * 1);
        padding-bottom: calc(var(--global-margin) * 0.8);

        .type-summary {
            .type-summary-expand-text {
                font-weight: bold;
                cursor: pointer;
                text-decoration: underline;
            }

            .price-col {
                font-size: calc(var(--global-font-size) * 1.2);
                font-weight: 600;
                color: var(--global-primary-background);
            }

            .desc-col {
                .desc-icon {
                    transition: transform 200ms ease-out;
                }

                &:hover {
                    .desc-icon {
                        transform: rotate(45deg);
                    }

                }

                &.active {
                    .desc-icon {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        .type-details {

            .price-col {
                color: var(--global-primary-background);
            }
        }
    }
